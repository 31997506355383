import React from 'react'
import TransitionLinkFade from '../TransitionLink/TransitionLinkFade'
import Media from '../Media'

class WorkItem extends React.Component {
  widthClasses = width => {
    switch (width) {
      case 'One-Quarter':
        return 'work-grid__item--1/4'
      case 'Half-Width':
        return 'work-grid__item--1/2'
      case 'One-Third':
        return 'work-grid__item--1/3'
      case 'Two-Thirds':
        return 'work-grid__item--2/3'
      default:
        return 'work-grid__item--1/2'
    }
  }
  render() {
    const {
      section,
      portraitCardImage,
      landscapeCardImage,
      title,
      uid,
      cardSize,
      cardDotColour,
      cardDisplay,
      cardVideoUrl,
      cardVideoPoster,
    } = this.props

    return (
      <div
        className={`work-grid__item ${this.widthClasses(
          cardSize
        )} mb-12 md:mb-0`}
      >
        <TransitionLinkFade
          className="work-grid__item-inner block h-full group transition-opacity"
          to={`/${section}/${uid}`}
          entry={{
            state: {
              headerSecondary_SlideIn: true,
            },
          }}
          exit={{
            state: {
              headerPrimary_FadeOut: true,
            },
          }}
        >
          <div className="absolute inset-0">
            <div
              className={`work-grid__item-circle w-4 h-4 absolute top-0 left-0 rounded-full z-50 ${
                cardDotColour === 'Black' ? 'bg-black' : 'bg-white'
              }`}
            ></div>

            {cardSize === 'One-Quarter' ? (
              <Media
                image={portraitCardImage}
                videoUrl={cardVideoUrl}
                videoPoster={cardVideoPoster}
                display={cardDisplay}
                className="relative w-full h-full z-20 hidden md:block"
              />
            ) : (
              <Media
                image={landscapeCardImage}
                videoUrl={cardVideoUrl}
                videoPoster={cardVideoPoster}
                display={cardDisplay}
                className="relative w-full h-full z-20 hidden md:block"
              />
            )}

            <Media
              image={landscapeCardImage}
              videoUrl={cardVideoUrl}
              videoPoster={cardVideoPoster}
              display={cardDisplay}
              className="relative w-full h-full z-20 md:hidden"
            />

            {title && (
              <h4 className="work-grid__item-title text-sm 2xl:text-base absolute pt-2 top-100 z-10">
                {title}
              </h4>
            )}
          </div>
        </TransitionLinkFade>
      </div>
    )
  }
}

export default WorkItem
