import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import WorkCard from '../components/Work/WorkCard'
import Layout from '../components/Layout'
import HeaderPrimary from '../components/Header/HeaderPrimary'
import Footer from '../components/Footer'
import { applyTheme, palette } from '../utils/theme'

const ArchivePage = ({ location, data }) => {
  const pageData = data.prismicArchive.data

  useEffect(() => {
    applyTheme(palette.dark)
  }, [])

  return (
    <Layout location={location}>
      <SEO
        title={pageData.meta_title || 'Archive'}
        description={pageData.meta_description || ''}
        location={location}
      />
      <HeaderPrimary />
      <div className="pt-60vh">
        <div className="js-scroll-target js-transition-link-target">
          <div className="px-gs">
            <div className="work-grid">
              {pageData.works
                .filter(item => item.work.document)
                .map((item, index) => {
                  const workItem = item.work.document
                  return (
                    <WorkCard
                      section="archive"
                      key={index}
                      uid={workItem.uid}
                      title={workItem.data.title}
                      cardSize={item.card_size}
                      cardDisplay={workItem.data.card_display}
                      cardDotColour={workItem.data.card_dot_colour}
                      cardVideoUrl={workItem.data.card_video_url}
                      cardVideoPoster={workItem.data.card_video_poster}
                      landscapeCardImage={workItem.data.card_image_landscape}
                      portraitCardImage={workItem.data.card_image_portrait}
                    />
                  )
                })}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

ArchivePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const archivePageQuery = graphql`
  query {
    prismicArchive {
      data {
        meta_description
        meta_title
        works {
          card_size
          work {
            document {
              ... on PrismicWork {
                uid
                data {
                  title
                  card_display
                  card_video_url
                  card_video_poster {
                    url
                  }
                  card_dot_colour
                  card_image_portrait {
                    alt
                    fluid(
                      imgixParams: { q: 80 }
                      srcSetBreakpoints: [640, 768, 1024, 1366]
                    ) {
                      srcSetWebp
                      srcWebp
                      sizes
                      src
                      srcSet
                      base64
                      aspectRatio
                    }
                  }
                  card_image_landscape {
                    alt
                    fluid(
                      imgixParams: { q: 80 }
                      srcSetBreakpoints: [640, 768, 1024, 1366]
                    ) {
                      srcSetWebp
                      srcWebp
                      sizes
                      src
                      srcSet
                      base64
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArchivePage
